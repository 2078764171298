import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["add_item", "template"]

  connect() {
  }

  add_association(event) {
    event.preventDefault()
    var content = this.templateTarget.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
    this.add_itemTarget.insertAdjacentHTML('beforebegin', content)
  }

  remove_association(event) {
    event.preventDefault()
    let confirmText;
    if (this.data.get("confirm-text")) {
      confirmText = this.data.get("confirm-text");
    } else {
      confirmText = "Êtes-vous sûr de vouloir supprimer cette association ?";
    }
    const c = confirm(confirmText)
    if (c == true) {
      let item = event.target.closest(".Form-nestedField")
      item.querySelector("input[name*='_destroy']").value = 1
      item.style.display = 'none'
    }
  }
}
