import { Controller } from "stimulus"
import * as Choices from 'choices.js';
import 'choices.js/public/assets/styles/choices.css';
import debounce from 'lodash/debounce';
import Rails from 'rails-ujs';


export default class extends Controller {
  static targets = ['selectField', 'placeNameField', 'form']
  static values = {
    removeItems: Boolean,
    submitFormOnChange: Boolean
  }

  connect() {
    this.form = this.hasFormTarget ? this.formTarget : this.element.closest('form');

    this.choicesElem = new Choices(this.selectFieldTarget, {
      noChoicesText: "Aucun résultat",
      searchChoices: false,
      removeItems: this.removeItemsValue,
      removeItemButton: this.removeItemsValue,
      itemSelectText: "",
      sortFn: () => {},
    });

    this.choicesElem.passedElement.element.addEventListener(
      'search',
      debounce(async e => {
        fetch(`/api/places?q=${encodeURIComponent(e.detail.value)}`).then((response) => {
          response.json().then((data) => {
            this.choicesElem.setChoices(data.places, 'value', 'label', true)
          });
        })
        .catch((error) => {
          console.log(error);
        });
      }, 400)
    )

    this.choicesElem.passedElement.element.addEventListener(
      'addItem', (event) => {
        if (this.hasPlaceNameFieldTarget) {
          this.placeNameFieldTarget.value = event.detail.label;
        }
        if (this.submitFormOnChangeValue) {
          this.submitForm();
        }
      }
    )

    this.choicesElem.passedElement.element.addEventListener(
      'removeItem', (event) => {
        if (this.hasPlaceNameFieldTarget) {
          this.placeNameFieldTarget.value = "";
        }
        if (this.submitFormOnChangeValue) {
          this.submitForm();
        }
      }
    )
  }

  submitForm() {
    if (this.form.dataset['remote'] == "true") {
      Rails.fire(this.form, 'submit');
    } else {
      this.form.submit();
    }
  }

  disconnect() {
    this.choicesElem.destroy();
  }
}
